import Vue from "vue";
import Vuex from "vuex";
import { firestoreAction, vuexfireMutations } from "vuexfire";
import app from 'firebase/app'
const firebaseConfig = {
    apiKey: "AIzaSyDJEmqoV5htpJTzLv-0tznxigrzQXiDE9I",
    authDomain: "vexpo-92b48.firebaseapp.com",
    projectId: "vexpo-92b48",
    storageBucket: "vexpo-92b48.appspot.com",
    messagingSenderId: "293938355125",
    appId: "1:293938355125:web:9480c489f7d05c4df917fc",
    measurementId: "G-RVPQEJ8D8M"
};

// Initialize Firebase
app.initializeApp(firebaseConfig);
import 'firebase/firestore'
export const firebase = app
export const db = app.firestore()


Vue.use(Vuex);

const serializeObj = (doc) => {
    if (!doc)
        return doc;
    const data = doc.data();
    return {
        ...data,
        objectId: data.objectId || data.id,
        id: doc.id,
    }
}

const serializeManufacturer = (doc) => {
    if (!doc)
      return doc;
    return {
        id: doc.id,
        ...doc.data()
    }
}

export default new Vuex.Store({
    state: {
        user: null,
        userProfile: null,
        expoManufacturers: [],
        supportMessages: [],
        expoObjects: [],
        expoEvents: [],
        expoRewards: [],
    },
    getters: {
        expoObjectById: (state) => (id) => {
            return state.expoObjects.find(thing => thing.id === id);
        },
        expoManufacturerById: (state) => (id) => {
            return state.expoManufacturers.find(thing => thing.id === id);
        },
    },
    mutations: {
        user(state, user) {
            state.user = user;
        },
        ...vuexfireMutations
    },
    actions: {
        likeObject(context, {objectId}) {
          Vue.$log.debug(context, objectId)
          return objectId &&
          context.state.expoObjects.find(o => o.objectId === objectId) &&
          context.state.userProfile && !(context.state.userProfile.objectsLikes || []).find(o => o === objectId) ?
            firebase.functions().httpsCallable('likeObject')({
            objectId: objectId,
          }).then(Vue.$log.debug).catch(Vue.$log.error) : new Promise((resolve, reject) => {
              reject({error: "objectId is null ot not found"})
          });
        },
        collectStar(context, {starId}) {
          Vue.$log.debug(context, starId)
          return starId &&
          context.state.userProfile && !(context.state.userProfile.stars || []).find(o => o === starId) ?
            firebase.functions().httpsCallable('collectStar')({
            starId: starId,
          }).then(Vue.$log.debug).catch(Vue.$log.error) : new Promise((resolve, reject) => {
              reject({error: "starId is null or already collected"})
          });
        },
        sendSupportMessage(context, {text, to, username}) {
          Vue.$log.debug(context, text, to)
          return firebase.functions().httpsCallable('sendSupportMessage')({
            text: text,
            to: to,
            username: username,
          }).then(Vue.$log.debug).catch(Vue.$log.error);
        },
        signOut() {
          firebase.auth().signOut().catch(() => {});
          window.location.assign(`/`);
        },
        unbindAll: firestoreAction( ({ unbindFirestoreRef }) => {
            unbindFirestoreRef('userProfile')
            unbindFirestoreRef('expoObjects')
            unbindFirestoreRef('expoEvents')
            unbindFirestoreRef('expoRewards')
            unbindFirestoreRef('expoManufacturers')
            unbindFirestoreRef('supportMessages')
        }),
        updateUserProfile: firestoreAction((context, data) => {
            db.collection('users')
                .doc(context.state.userProfile.uid)
                .update(data)
                .then(() => {
                  Vue.$log.debug('user updated!', data)
                })
        }),
        unbindUserProfile: firestoreAction(({ unbindFirestoreRef }) => {
             unbindFirestoreRef('userProfile')
        }),
        bindUserProfile: firestoreAction(({ bindFirestoreRef }, userId) => {
            return bindFirestoreRef(
              'userProfile',
              db.collection('users').doc(userId)
            )
        }),
        bindExpoObjects: firestoreAction(({ bindFirestoreRef }) => {
            return bindFirestoreRef(
              'expoObjects',
              db.collection('objects').orderBy('name'),
              {
                  serialize: serializeObj
              }
            )
        }),
        unbindExpoObjects: firestoreAction(({ unbindFirestoreRef }) => {
            unbindFirestoreRef('expoObjects')
        }),
        bindExpoEvents: firestoreAction(({ bindFirestoreRef }) => {
            return bindFirestoreRef(
              'expoEvents',
              db.collection('events').orderBy('name')
            )
        }),
        unbindExpoEvents: firestoreAction(({ unbindFirestoreRef }) => {
            unbindFirestoreRef('expoEvents')
        }),
        bindExpoRewards: firestoreAction(({ bindFirestoreRef }) => {
            return bindFirestoreRef(
              'expoRewards',
              db.collection('rewards').orderBy('name')
            )
        }),
        unbindExpoRewards: firestoreAction(({ unbindFirestoreRef }) => {
            unbindFirestoreRef('expoRewards')
        }),
        bindExpoManufacturers: firestoreAction(({ bindFirestoreRef }) => {
            return bindFirestoreRef(
              'expoManufacturers',
              db.collection('manufacturers').orderBy('name'),
              {
                  serialize: serializeManufacturer
              }
            )
        }),
        unbindExpoManufacturers: firestoreAction(({ unbindFirestoreRef }) => {
            unbindFirestoreRef('expoManufacturers')
        }),
        unbindSupportMessages: firestoreAction(({ unbindFirestoreRef }) => {
            unbindFirestoreRef('supportMessages')
        }),
        bindSupportMessages: firestoreAction(({ bindFirestoreRef }, userId) => {
            return bindFirestoreRef(
              'supportMessages',
              db.collection('support_messages')
                .orderBy('timestamp', 'asc')
                .where('client_id', '==', userId),
            )
        })
    }
});
