import Vue from 'vue'
import App from './App.vue'
import router from './router'
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/functions"
import "firebase/firestore"
import store from "@/store"
import VueLogger from 'vuejs-logger';
import Chat from 'vue-beautiful-chat'
import VueI18n from 'vue-i18n';
import localization from './assets/localization.json'
import { VueHammer } from 'vue2-hammer'

// firebase.analytics();
firebase.auth().onAuthStateChanged(user => {
    Vue.$log.debug('onAuthStateChanged: ', user);
    store.commit('user', user);
    if (user) {
      if (!user.isAnonymous) {
        store.dispatch('bindUserProfile', user.uid)
        store.dispatch('bindSupportMessages', user.uid)
      }
      store.dispatch('bindExpoObjects');
      // store.dispatch('bindExpoEvents');
      // store.dispatch('bindExpoRewards');
      store.dispatch('bindExpoManufacturers');
    } else {
      store.dispatch('unbindAll')
    }
});

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe();
      resolve(user);
    }, reject);
  }
)};

const isProduction = process.env.NODE_ENV === 'production';

if (!isProduction) {
  // firebase.functions().useFunctionsEmulator("http://localhost:5001");
}

const options = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
};

Vue.use(VueHammer);
Vue.use(VueLogger, options);
Vue.use(VueI18n);
Vue.use(Chat)
Vue.config.productionTip = false

Array.prototype.contains = function (v) {
  for (let i = 0; i < this.length; i++) {
    if (this[i] === v) return true;
  }
  return false;
};

// Hide method from for-in loops
Object.defineProperty(Array.prototype, "contains", {enumerable: false});

Array.prototype.unique = function () {
  const arr = [];
  for (let i = 0; i < this.length; i++) {
    if (!arr.contains(this[i])) {
      arr.push(this[i]);
    }
  }
  return arr;
}

// Hide method from for-in loops
Object.defineProperty(Array.prototype, "unique", {enumerable: false});

Array.prototype.equals = function (array) {
    // if the other array is a falsy value, return
    if (!array)
        return false;

    // compare lengths - can save a lot of time
    if (this.length !== array.length)
        return false;

    for (var i = 0, l=this.length; i < l; i++) {
        // Check if we have nested arrays
        if (this[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if (!this[i].equals(array[i]))
                return false;
        }
        else if (this[i] !== array[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
}

// Hide method from for-in loops
Object.defineProperty(Array.prototype, "equals", {enumerable: false});

const i18n = new VueI18n({
  locale: "ru",
  messages: localization,
  postTranslation: (str) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
  }
})

new Vue({
    router: router,
    store: store,
    i18n: i18n,
    render: h => h(App)
}).$mount('#app')
