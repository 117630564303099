<template>
  <div class="language">
    <span v-bind:class="['language-text js-language-text', $i18n.locale === code ? 'select' : '']"
          @click="setLocale(code)">{{ name }}</span>
  </div>
</template>
<script>
export default {
  name: 'LocaleSwitcher',
  methods: {
    setLocale(lang) {
      this.$log.debug(`setLocale("${lang}")`)
      if (this.$i18n.locale !== lang) {
        localStorage.setItem('lang',lang);
        this.$i18n.locale = localStorage.getItem('lang');
      }}
  },
  mounted(){
    if(localStorage.getItem('lang')!=null){
      this.$i18n.locale = localStorage.getItem('lang');
    }
  },
  props: [
      "code",
      "name",
  ]
}
</script>
